import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground } from 'docz';
import { UseInViewExample } from './use-in-view.example.tsx';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "useinview"
    }}>{`useInView`}</h1>
    <p>{`Determine if an element is currently in the viewport or not using
IntersectionObserver.`}</p>
    <p><strong parentName="p">{`Note:`}</strong>{` This uses the
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API"
      }}>{`IntersectionObserver API`}</a>{`
which is
`}<a parentName="p" {...{
        "href": "https://caniuse.com/#feat=intersectionobserver"
      }}>{`not available in all browsers yet`}</a>{`,
so you might want to polyfill it before using this hook.`}</p>
    <h2 {...{
      "id": "usage"
    }}>{`Usage`}</h2>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React, { useRef } from 'react';
import { useInView } from '@fransvilhelm/hooks';

const Reveal = ({ children }) => {
  const ref = useRef();
  const inView = useInView(ref);
  return (
    <div
      ref={ref}
      style={{
        opacity: inView ? 1 : 0,
        transition: 'opacity 1s ease-in-out',
      }}
    >
      {children}
    </div>
  );
};
`}</code></pre>
    <h2 {...{
      "id": "example"
    }}>{`Example`}</h2>
    <Playground __position={0} __code={'<UseInViewExample />'} __scope={{
      props,
      DefaultLayout,
      Playground,
      UseInViewExample,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <UseInViewExample mdxType="UseInViewExample" />
    </Playground>
    <h2 {...{
      "id": "parameters"
    }}>{`Parameters`}</h2>
    <p><inlineCode parentName="p">{`useInView`}</inlineCode>{` accepts two arguments – the element (as a ref container) to observe
and optional options to attach to the IntersectionObserver.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Param`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Required`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Default`}</th>
          <th parentName="tr" {...{
            "align": "left"
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`ref`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`RefObject\\<Element`}{`>`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`true`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`A ref object with a current value of an HTML Element`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": "left"
          }}>{`options`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`IntersectionObserverInit`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`false`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}>{`-`}</td>
          <td parentName="tr" {...{
            "align": "left"
          }}><a parentName="td" {...{
              "href": "https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API#Creating_an_intersection_observer"
            }}>{`IntersectionObserver options`}</a></td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "returns"
    }}>{`Returns`}</h2>
    <p><inlineCode parentName="p">{`useInView`}</inlineCode>{` returns a boolean indication if the current element is in view or
not.`}</p>
    <div style={{
      marginBottom: '100vh'
    }} />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      